import React, { ComponentType, useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Tab, Tabs, Typography, Box } from '@material-ui/core';
import { APP_NAME, roleHomepage } from 'constants/index';
import useStyles from './styles';
import AppLogo from 'assets/logo.svg';
import { IAppState } from 'types';
import menuItems from './menuItems';
import LanguageSelect from '../language-select';
import { logoutRequest } from 'actions/auth';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';

interface OwnProps {
  toggleMenuDisplay?: Function;
  role: 'client' | 'operator' | 'admin' | string;
}

interface DispatchProps {
  logout: Function;
}

interface StateProps {
  locale: string;
}

type Props = OwnProps & StateProps & RouteComponentProps & DispatchProps;

const SidebarMenu = ({ toggleMenuDisplay, role, history, logout }: Props): React.ReactNode => {

  const [tab, setTab] = useState(roleHomepage[role]);
  const { indicator, logo, logoLink, tabStyle, langSelect, logoutStyles } = useStyles();
  const {
    location: { pathname },
  } = history;

  const filteredRoutes = menuItems.filter(({ allowedRoles }) => {
    if (!allowedRoles) {
      return true;
    }

    // @TODO: Talk with Ruslan to implement the correct role into /me
    return allowedRoles.includes('operator');
  });

  const routes = filteredRoutes.map(({ path }) => path);

  useEffect(() => {
    if (pathname !== tab) {
      const getTab = () => (routes.includes(tab) ? pathname : roleHomepage[role]);

      setTab(getTab());
    }
  }, [pathname, routes, tab, role]);

  const goTo = (to: string) => {
    if (pathname !== to) {
      history.push(to);
      if (toggleMenuDisplay) {
        toggleMenuDisplay();
      }
    }
  };

  const handleChange = (value: string) => {
    setTab(value);
    goTo(value);
  };

  return (
    <>
      <Link to={roleHomepage[role]} className={logoLink}>
        <img src={AppLogo} alt={APP_NAME} className={logo} width={92} height={100} />
      </Link>
      <Box className={langSelect}>
        <LanguageSelect location="sidebar"/>
      </Box>
      <Tabs value={tab} onChange={(_, value) => handleChange(value)} orientation="vertical" classes={{ indicator }}>
        {filteredRoutes.map(({ name, path, icon }) => (
          <Tab
            disableRipple
            key={path}
            className={tabStyle}
            label={(
              <Box display="flex" gridGap="10px" clone>
                <Typography variant="body2">
                  {icon}
                  <FormattedMessage id={name} />
                </Typography>
              </Box>
            )}
            value={path}
            selected={tab === path}
          />
        ))}
      </Tabs>
      <Box role="button" className={logoutStyles} onClick={() => logout()}>
        <SystemUpdateAltIcon style={{ transform: 'rotate(-90deg)' }}/>
        <Typography variant="body2">
          <FormattedMessage id="login.logout" />
        </Typography>
      </Box>
    </>
  );
};

SidebarMenu.defaultProps = {
  toggleMenuDisplay() {},
};

const mapStateToProps = ({ translation }: IAppState): StateProps => ({
  locale: translation.locale,
});

const mapDispatchToProps = {
  logout: logoutRequest,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(SidebarMenu) as ComponentType<OwnProps>;
