import React, { lazy, Suspense, useMemo } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useSelector } from 'react-redux';
import theme from '../theme';
import history from '../utils/history';
import MainLayoutRoute from './main-layout-route';
import { Loader } from '../components';
import { roleHomepage } from '../constants';
import { IAppState } from '../types';
import Notifier from '../components/notifier';
import useSnackbarStyles from './styles';

const LazyLogin = lazy(() => import('../containers/auth-2/login-container/index'));
const LazyRegister = lazy(() => import('../containers/auth-2/register'));
const LazyResetPassword = lazy(() => import('../containers/auth-2/reset-password'));
const LazyForgotPassword = lazy(() => import('../containers/auth-2/forgot-password'));
const LazyAgentList = lazy(() => import('../containers/agent/list'));
const LazyHistory = lazy(() => import('../containers/history-page'));
const LazyMapPage = lazy(() => import('../containers/map-page'));
const LazySettings = lazy(() => import('../containers/settings'));

const Routes = () => {
  const { role, isAuthenticated } = useSelector(({ auth: { data, isAuthenticated } }: IAppState) => ({
    role: (data || {}).role,
    isAuthenticated,
  }));

  const snackbarStyles = useSnackbarStyles();

  const redirectUrl = useMemo(() => roleHomepage[role] || '/map', [role]);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={history}>
        <SnackbarProvider maxSnack={10} hideIconVariant classes={snackbarStyles}>
          <>
            <Notifier />
            <Switch>
              <Route exact path="/" render={() => <Redirect to={isAuthenticated ? redirectUrl : '/login'} />} />

              <Route
                path="/login"
                render={() =>
                  (isAuthenticated ? (
                    <Redirect to={redirectUrl} />
                  ) : (
                    <Suspense fallback={<Loader isLoading />}>
                      <LazyLogin />
                    </Suspense>
                  ))}
              />
              <Route
                path="/register"
                render={() =>
                  // TODO use "currentStep" from localStorage to display welcome screen or not
                  (isAuthenticated ? (
                    <Redirect to={redirectUrl} />
                  ) : (
                    <Suspense fallback={<Loader isLoading />}>
                      <LazyRegister />
                    </Suspense>
                  ))}
              />
              <Route
                path="/reset-password"
                render={() =>
                  (isAuthenticated ? (
                    <Redirect to={redirectUrl} />
                  ) : (
                    <Suspense fallback={<Loader isLoading />}>
                      <LazyResetPassword />
                    </Suspense>
                  ))}
              />
              <Route
                path="/forgot-password"
                render={() =>
                  (isAuthenticated ? (
                    <Redirect to={redirectUrl} />
                  ) : (
                    <Suspense fallback={<Loader isLoading />}>
                      <LazyForgotPassword />
                    </Suspense>
                  ))}
              />

              <MainLayoutRoute
                path="/map"
                exact
                // @TODO: fix any type
                render={(matchprops: any) => (
                  <Suspense fallback={<Loader isLoading />}>
                    <LazyMapPage {...matchprops} />
                  </Suspense>
                )}
              />

              <MainLayoutRoute
                path="/agents"
                exact
                render={(matchprops: any) => (
                  <Suspense fallback={<Loader isLoading />}>
                    <LazyAgentList {...matchprops} />
                  </Suspense>
                )}
              />

              <MainLayoutRoute
                path="/history"
                exact
                render={(matchprops: any) => (
                  <Suspense fallback={<Loader isLoading />}>
                    <LazyHistory {...matchprops} />
                  </Suspense>
                )}
              />

              <MainLayoutRoute
                path="/settings"
                exact
                render={(matchprops: any) => (
                  <Suspense fallback={<Loader isLoading />}>
                    <LazySettings {...matchprops} />
                  </Suspense>
                )}
              />
              <Route render={() => <Redirect to={isAuthenticated ? redirectUrl : '/login'} />} />
            </Switch>
          </>
        </SnackbarProvider>
      </Router>
    </MuiThemeProvider>
  );
};

export default Routes;
